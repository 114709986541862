<template>
  <v-flex xl12 lg12 md12>
    <v-layout row wrap>
        <v-flex lg3 md3 class="hidden-md-and-down">
          <v-toolbar color="primary" dark>FOTO / FIRMA</v-toolbar>
          <FotoFirma />
          <br />
          <v-toolbar color="primary" dark>INFORMACIÓN GENERAL</v-toolbar>
          <Resumen />
        </v-flex>
      <v-flex lg9 md12 sm12 xs12>
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
          icons-and-text
          class="fixed-tabs-bar"
          color="red"
          centered
          show-arrows
        >
        <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab>
                REGISTRO POSTULACION
                <v-icon>mdi-file-document</v-icon>
            </v-tab>
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12" class="py-2">
                      <h5 class="grey--text">NOMBRE CONVOCATORIA:</h5>
                      <p class="body-2 black--text">{{postulacion.convocatoria.titulo}}</p>
                    </v-col>
                    <v-col lg="4" class="py-2">
                      <h5 class="grey--text">TIPO POSTULACION:</h5>
                      <p class="body-2 black--text">{{postulacion.nacional?'NACIONAL':'DEPARTAMENTAL'}}</p>
                    </v-col>
                    <v-col lg="4" class="py-2">
                      <h5 class="grey--text">FECHA PRESENTACION:</h5>
                      <p class="body-2 black--text">{{formatDateText(postulacion.fecha_creacion)}}</p>
                    </v-col>
                    <v-col lg="4" class="py-2">
                      <h5 class="grey--text">DEPARTAMENTO SOLICITUD:</h5>
                      <p class="body-2 black--text">{{getNombreParametro(postulacion,'departamento_solicitud')}}</p>
                    </v-col>
                    <v-col lg="4" class="py-2">
                      <h5 class="grey--text">DEPARTAMENTO POSTULACION:</h5>
                      <p class="body-2 black--text" v-if="postulacion.nacional">
                          <v-chip small color="primary">NACIONAL</v-chip>
                      </p>
                      <p class="body-2 black--text" v-else>{{getNombreParametro(postulacion,'departamento_postulacion')}}</p>
                    </v-col>
                    <v-col lg="4" class="py-2">
                      <h5 class="grey--text">ESTADO POSTULACION:</h5>
                      <p class="body-2 black--text">
                        <v-chip small dark color="secondary">REGISTRADO</v-chip>
                      </p>
                    </v-col>
                  </v-row> 
                </v-card-text>
              </v-card>
            </v-tab-item>
         
            <v-tab >
                FORMACIÓN
                <v-badge color="error" overlap>
                  <span slot="badge">{{postulacion.formaciones.length}}</span>
                <v-icon >mdi-school</v-icon>
            </v-badge> 
            </v-tab>
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <formaciones 
                    :actions="false"
                    :urlAdd="urlAddFormacion"
                    :globalAdd="false"
                    :add="getValidVencimiento(postulacion.convocatoria.fecha_caducidad)"
                    :formaciones="postulacion.formaciones" 
                    :general="false"
                    :datosCreacion="true"
                    :areas="areasFormacion"
                    @nuevo="addFormacionesConvocatoria"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab >
                EXPERIENCIA
                <v-badge color="error" overlap>
                  <span slot="badge">{{postulacion.experiencias.length}}</span>
                <v-icon >mdi-briefcase</v-icon>
                </v-badge> 
            </v-tab>
            <v-tab-item>
               <v-card>
                <v-card-text>
                  <experiencias 
                    :actions="false"
                    :urlAdd="urlAddExperiencia"
                    :globalAdd="false"
                    :add="getValidVencimiento(postulacion.convocatoria.fecha_caducidad)"
                    :experiencias="postulacion.experiencias"
                    :general="false"
                    :datosCreacion="true"
                    :areas="areasFormacion"
                    @nuevo="addExperienciasConvocatoria"
                  />
                </v-card-text>
               </v-card>
            </v-tab-item>

            <v-tab >
                DOCENCIA
                <v-badge color="error" overlap>
                  <span slot="badge">{{postulacion.docencias.length}}</span>
                <v-icon >mdi-teach</v-icon>
                </v-badge>
            </v-tab>
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <docencias 
                    :actions="false"
                    :urlAdd="urlAddDocencia"
                    :globalAdd="false"
                    :add="getValidVencimiento(postulacion.convocatoria.fecha_caducidad)"
                    :docencias="postulacion.docencias"
                    :general="false"
                    @nuevo="addDocenciasConvocatoria"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab>
                PRODUCCIONES
                 <v-badge color="error" overlap>
                  <span slot="badge">{{postulacion.producciones_intelectuales.length}}</span>
                <v-icon >mdi-book</v-icon>
                </v-badge>
            </v-tab>
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <producciones 
                    :actions="false"
                    :urlAdd="urlAddProduccionIntelectual"
                    :globalAdd="false"
                    :add="getValidVencimiento(postulacion.convocatoria.fecha_caducidad)"
                    :producciones="postulacion.producciones_intelectuales"
                    :general="false"
                     @nuevo="addProduccionesConvocatoria"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            </v-tabs>
      </v-flex>
         </v-layout>
     </v-flex>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
import mixinParametro from "@/mixins/parametro"
export default {
  props:{
      postulacion:{
          type:Object,
          default:()=>{
              return {
                  formaciones:[],
                  experiencias:[],
                  docencias:[],
                  producciones_intelectuales:[]
              }
          }
      }
  },
  mixins:[
    mixinDateTime,
    mixinParametro
  ],
  components: {
    FotoFirma: () => import("@/components/FotoFirma"),
    Resumen: () => import("@/components/Resumen"),
    'formaciones':()=>import("@/components/Formacion"),
    'experiencias':()=>import("@/components/Experiencia"),
    'docencias':()=>import("@/components/Docencia"),
    'producciones':()=>import("@/components/ProduccionIntelectual"),
  },
  computed: {
    fotoAbogado() {
      return this.$store.getters.getFotoAbogado;
    },
    firmaAbogado() {
      return this.$store.getters.getFirmaAbogado;
    },
    areasFormacion(){
      return this.$store.state.session.parametros.area_derecho
    },
    urlAddFormacion(){
      return `abogado-ciudadano/convocatorias/${this.$route.params.id}/formaciones`
    },
    urlAddExperiencia(){
      return `abogado-ciudadano/convocatorias/${this.$route.params.id}/experiencias`
    },
    urlAddDocencia(){
      return `abogado-ciudadano/convocatorias/${this.$route.params.id}/docencias`
    },
    urlAddProduccionIntelectual(){
      return `abogado-ciudadano/convocatorias/${this.$route.params.id}/producciones-intelectuales`
    }
  },
  data(){
    return{
      tab:'/ruanna/defensor',
    }
  },
  created(){
    this.$store.commit("setTituloPricipal", "Información del Sobre de Postulación")
  },
  mounted(){
    this.verificarAlerta()
  },
  methods:{
    addFormacionesConvocatoria(data){
      this.postulacion.formaciones=data
    },
    addExperienciasConvocatoria(data){
      this.postulacion.experiencias=data
    },
    addDocenciasConvocatoria(data){
      this.postulacion.docencias=data
    },
    addProduccionesConvocatoria(data){
      this.postulacion.producciones_intelectuales=data
    },
    getValidVencimiento(date){
      let date1 = new Date()
      let date2 = new Date(date+'T23:59:59')
      let diference =  date2.getTime() - date1.getTime();
      return diference>0
    },
    verificarAlerta(){
      if(this.getValidVencimiento(this.postulacion.convocatoria.fecha_caducidad)){
        this.$store.commit('addAlerta',{
          id: 1,
          type: 'info',
          text: `Usted puede actualizar sus documentos de respaldo hasta el ${this.formatDateText(this.postulacion.convocatoria.fecha_caducidad)}`
        })
      }
    }
    
  }
}
</script>
<style>
.rpa-tab-active {
  color: red !important;
}
.v-tabs.fixed-tabs-bar.v-tabs--centered.v-tabs--icons-and-text.theme--dark {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 60px;
  z-index: 2;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-select .v-chip--disabled,
.theme--light.v-select.v-input--is-disabled .v-select__selections,
.theme--light.v-select .v-select__selection--disabled {
  color: black !important;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-textarea.v-input--is-disabled {
  color: black !important;
}
.tab-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>